.card-card {
  position: relative;
}
.card-header {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.card-icon {
  height: 50px;
  object-fit: cover;
}
.card-heading {
  color: rgb(18, 40, 33);
  font-size: 24px;
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.card-text {
  color: rgb(18, 40, 33);
  max-width: 600px;
  font-family: Lato;
  line-height: 24px;
}
