.links-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.links-button {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.links-button1 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.links-button2 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  border-color: rgba(18, 40, 33, 1);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
  border-left-width: 1px;
}
